import * as React from "react";
import Body from "./Body.tsx";
import { ToastContainer } from "react-toastify";
import DashHeader from "../sidebar/DashHeader.tsx";
import Sidebar, { SidebarItem } from "./SideBarNew.tsx";
import {
  DashboardSVG,
  RentalScheduleSVG,
  InventorySVG,
  InvoicesSVG,
  SupportSVG,
  DocumentSVG,
  NewBusinessSVG,
  EmployeeDirectorySvg,
  ProductSVG,
  AdminSettings,
  ProfileSVG,
} from "../../../assets/images/Images.js";
import Bottombar from "./Bottombar.tsx";
import { useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

interface Props {
  children: React.ReactNode;
}

export default function Main({ children }: Props) {
  const [expandedMain, setExpandedMain] = React.useState(true);

  return (
    <>
      <ToastContainer />
      <div
        className={`fixed top-0 left-0 right-0 transition-all w-screen ${
          expandedMain ? "sm:ml-0" : "sm:ml-0"
        }`}
      >
        <DashHeader />
      </div>
      <Body sideView={<SidebarNew setExpandedMain={setExpandedMain} />}>
        <div className="p-0 bg-white">
          <div
            className={`mt-12 transition-all ${
              expandedMain ? "sm:ml-[18%]" : "sm:ml-24"
            }`}
          >
            {children}
          </div>
        </div>
      </Body>
      <Bottombar />
    </>
  );
}

interface SidebarNewProps {
  setExpandedMain: (expanded: boolean) => void;
}

function SidebarNew({ setExpandedMain }: SidebarNewProps) {
  const { pathname } = useLocation();
  const inventoryPaths = ["/dashboard/inventory", "/dashboard/inventorylist"];
  const vendorinventoryPaths = [
    "/dashboard/inventory",
    "/client/vendor-inventory",
    "/dashboard/add-inventory",
  ];

  const role = secureLocalStorage.getItem("role");
  const platform = secureLocalStorage.getItem("platform");

  const isActivePath = (pathname: string, paths: string[]): boolean => {
    return paths.some((path) => {
      const pathPattern = new RegExp(`^${path.replace(/:\w+/g, "\\w+")}$`);
      return pathPattern.test(pathname);
    });
  };

  if (role === "vendor" && platform === "epp") {
    return (
      <Sidebar setExpandedMain={true}>
        <SidebarItem
          icon={
            <ProductSVG color={pathname === "/client" ? "#fff" : "#00006D"} />
          }
          text={"Client"}
          alert={pathname === "/client"}
          active={pathname === "/client"}
          href="/client"
        />
        <SidebarItem
          icon={
            <InvoicesSVG
              color={pathname === "/client/view-orders" ? "#fff" : "#00006D"}
            />
          }
          text={"Orders"}
          alert={pathname === "/client/view-orders"}
          active={pathname === "/client/view-orders"}
          href="/client/view-orders"
        />

        <SidebarItem
          icon={
            <InventorySVG
              color={
                isActivePath(pathname, vendorinventoryPaths)
                  ? "#fff"
                  : "#00006D"
              }
            />
          }
          text={"Master Inventory"}
          alert={isActivePath(pathname, vendorinventoryPaths)}
          active={isActivePath(pathname, vendorinventoryPaths)}
          href="/client/vendor-inventory"
        />
        <SidebarItem
          icon={
            <SupportSVG
              color={pathname === "/client/vendor-profile" ? "#fff" : "#00006D"}
            />
          }
          text={"Profile"}
          alert={pathname === "/client/vendor-profile"}
          active={pathname === "/client/vendor-profile"}
          href="/client/vendor-profile"
        />
        <SidebarItem
          icon={
            <InvoicesSVG
              color={pathname === "/client/vendor-invoice" ? "#fff" : "#00006D"}
            />
          }
          text={"Invoice"}
          alert={pathname === "/client/vendor-invoice"}
          active={pathname === "/client/vendor-invoice"}
          href="/client/vendor-invoice"
        />
      </Sidebar>
    );
  }

  return (
    <Sidebar setExpandedMain={true}>
      {role === "employee" && platform === "epp" ? (
        <>
          <SidebarItem
            icon={
              <ProductSVG
                color={pathname === "/dashboard/products" ? "#fff" : "#00006D"}
              />
            }
            text={"Products"}
            alert={pathname === "/dashboard/products"}
            active={pathname === "/dashboard/products"}
            href="/dashboard/products"
          />
          <SidebarItem
            icon={
              <DocumentSVG
                color={
                  pathname === "/dashboard/activerentals" ? "#fff" : "#00006D"
                }
              />
            }
            text={"View Orders"}
            alert={pathname === "/dashboard/activerentals"}
            active={pathname === "/dashboard/activerentals"}
            href="/dashboard/activerentals"
          />
          <SidebarItem
            icon={
              <DocumentSVG
                color={
                  pathname === "/dashboard/employee-active-rentals"
                    ? "#fff"
                    : "#00006D"
                }
              />
            }
            text={"Active Rentals"}
            alert={pathname === "/dashboard/employee-active-rentals"}
            active={pathname === "/dashboard/employee-active-rentals"}
            href="/dashboard/employee-active-rentals"
          />
        </>
      ) : (
        <>
          <SidebarItem
            icon={
              <DashboardSVG
                color={pathname === "/dashboard" ? "#fff" : "#00006D"}
              />
            }
            text={"Home"}
            alert={pathname === "/dashboard"}
            active={pathname === "/dashboard"}
            href="/dashboard"
          />
          {role === "employer" && platform === "epp" && (
            <>
              <SidebarItem
                icon={
                  <DocumentSVG
                    color={
                      pathname === "/dashboard/activerentals"
                        ? "#fff"
                        : "#00006D"
                    }
                  />
                }
                text={"Device Requests"}
                alert={pathname === "/dashboard/activerentals"}
                active={pathname === "/dashboard/activerentals"}
                href="/dashboard/activerentals"
              />
              <SidebarItem
                icon={
                  <DocumentSVG
                    color={
                      pathname === "/dashboard/orders" ? "#fff" : "#00006D"
                    }
                  />
                }
                text={"Orders"}
                alert={pathname === "/dashboard/orders"}
                active={pathname === "/dashboard/orders"}
                href="/dashboard/orders"
              />
            </>
          )}
          <SidebarItem
            icon={
              <RentalScheduleSVG
                color={
                  pathname === "/dashboard/rentalschedule" ? "#fff" : "#00006D"
                }
              />
            }
            text={"Rental Schedule"}
            alert={pathname === "/dashboard/rentalschedule"}
            active={pathname === "/dashboard/rentalschedule"}
            href="/dashboard/rentalschedule"
          />
          <SidebarItem
            icon={
              <InventorySVG
                color={
                  isActivePath(pathname, inventoryPaths) ? "#fff" : "#00006D"
                }
              />
            }
            text={"Inventory"}
            alert={isActivePath(pathname, inventoryPaths)}
            active={isActivePath(pathname, inventoryPaths)}
            href="/dashboard/inventory"
          />
          <SidebarItem
            icon={
              <InvoicesSVG
                color={pathname === "/dashboard/invoices" ? "#fff" : "#00006D"}
              />
            }
            text={"Invoices"}
            alert={pathname === "/dashboard/invoices"}
            active={pathname === "/dashboard/invoices"}
            href="/dashboard/invoices"
          />
          <SidebarItem
            icon={
              <SupportSVG
                color={pathname === "/dashboard/support" ? "#fff" : "#00006D"}
              />
            }
            text={"Support"}
            alert={pathname === "/dashboard/support"}
            active={pathname === "/dashboard/support"}
            href="/dashboard/support"
          />
          <SidebarItem
            icon={
              <NewBusinessSVG
                color={
                  pathname === "/dashboard/newbusiness" ? "#fff" : "#00006D"
                }
              />
            }
            text={"New Business"}
            alert={pathname === "/dashboard/newbusiness"}
            active={pathname === "/dashboard/newbusiness"}
            href="/dashboard/newbusiness"
          />
          {role === "employer" && platform === "epp" && (
            <>
              <SidebarItem
                icon={
                  <EmployeeDirectorySvg
                    color={
                      pathname === "/dashboard/employee-directory"
                        ? "#fff"
                        : "#00006D"
                    }
                  />
                }
                text={"Employee Directory"}
                alert={pathname === "/dashboard/employee-directory"}
                active={pathname === "/dashboard/employee-directory"}
                href="/dashboard/employee-directory"
              />
              <SidebarItem
                icon={
                  <AdminSettings
                    color={
                      pathname === "/dashboard/admin-settings"
                        ? "#fff"
                        : "#00006D"
                    }
                  />
                }
                text={"Admin Settings"}
                alert={pathname === "/dashboard/admin-settings"}
                active={pathname === "/dashboard/admin-settings"}
                href="/dashboard/admin-settings"
              />
              <SidebarItem
                icon={
                  <ProductSVG
                    color={
                      pathname === "/dashboard/products" ? "#fff" : "#00006D"
                    }
                  />
                }
                text={"Products"}
                alert={pathname === "/dashboard/products"}
                active={pathname === "/dashboard/products"}
                href="/dashboard/products"
              />
            </>
          )}
        </>
      )}
    </Sidebar>
  );
}
