import * as React from "react";
import { Tooltip } from "@nextui-org/react";
import { VendorInvoiceType } from "@/src/types.ts";
import Row from "../../../../../../src/components/ui/Row";

interface Props {
  item: VendorInvoiceType;
  downloadInvoice: (name: string, url: string) => void;
}

export default function Action({ item, downloadInvoice }: Props) {
  const handleDownloadInvoice = React.useCallback(() => {
    downloadInvoice(item?.InvoiceNumber, item?.DocumentUrl);
  }, [downloadInvoice, item?.InvoiceNumber, item?.DocumentUrl]);

  return (
    <Row>
      <Tooltip content="download" size="sm">
        <button
          onClick={handleDownloadInvoice}
          className="text-textColorGrey underline cursor-pointer font-roboto font-light"
        >
          Download
        </button>
      </Tooltip>
    </Row>
  );
}
