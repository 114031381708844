import { baki, onePiece, salesforce } from "./utils/AxiosInterceptor.tsx";
import secureLocalStorage from "react-secure-storage";

export const LoginApi = (email: string, password: string) => {
  const formData = new FormData();
  formData.append("email", email);
  formData.append("password", password);
  return onePiece.post("/client-login", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

//rental api get
export const GetRentalListApi = () => {
  return onePiece.get(
    `/client-rental-schedule/${secureLocalStorage.getItem("code")}`,
    {
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
        code: `${secureLocalStorage.getItem("code")}`,
        email: `${secureLocalStorage.getItem("email")}`,
        name: `${secureLocalStorage.getItem("name")}`,
        platform: `${secureLocalStorage.getItem("platform")}`,
      },
    },
  );
};

export const GetInvoiceListApi = () => {
  return onePiece.get(`/client-invoice/${secureLocalStorage.getItem("code")}`, {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
    },
  });
};

export const GetScheduleNumbersApi = () => {
  return onePiece.get(
    `/client-schedules/${secureLocalStorage.getItem("code")}`,
    {
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
        code: `${secureLocalStorage.getItem("code")}`,
        email: `${secureLocalStorage.getItem("email")}`,
        name: `${secureLocalStorage.getItem("name")}`,
        platform: `${secureLocalStorage.getItem("platform")}`,
      },
    },
  );
};

export const GetClientSupportApi = () => {
  return onePiece.get(`/client-support`, {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
    },
  });
};

export const GetScheduleNumberInventoryInfo = (scheduleNumber: string) => {
  return onePiece.get(`/client-inventory-details/${scheduleNumber}`, {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
    },
  });
};

export const GetScheduleNumberQCInfo = (scheduleNumber: string) => {
  return onePiece.get(`/client-device/${scheduleNumber}`, {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
    },
  });
};

export const DownloadQrCodes = (scheduleNumber: string) => {
  return onePiece.get(`/client-qr/${scheduleNumber}`, {
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
      Company: "connect",
    },
  });
};

export const UpdateDeviceRemarkApi = (serialNumber: string, remark: string) => {
  return onePiece.put(
    `/client-device-remarks/${serialNumber}`,
    {
      remark,
    },
    {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
        code: `${secureLocalStorage.getItem("code")}`,
        email: `${secureLocalStorage.getItem("email")}`,
        name: `${secureLocalStorage.getItem("name")}`,
        platform: `${secureLocalStorage.getItem("platform")}`,
      },
    },
  );
};

export const GetUpcomingQcClient = (start_date: string, end_date: string) => {
  return onePiece.post(
    `/client-upcoming-qc`,
    { start_date, end_date },
    {
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
        code: `${secureLocalStorage.getItem("code")}`,
        email: `${secureLocalStorage.getItem("email")}`,
        name: `${secureLocalStorage.getItem("name")}`,
        platform: `${secureLocalStorage.getItem("platform")}`,
      },
    },
  );
};

//latest update data api
export const GetlatestListApi = () => {
  return onePiece.get(`/client-latestupdates`, {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
    },
  });
};

//dashboard client user data
export const GetMraUrlApi = () => {
  return onePiece.get(`/client-mra`, {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
    },
  });
};

// EPP - APIS
export const EppLoginApi = (email: string, password: string) => {
  const formData = new FormData();
  formData.append("email", email);
  formData.append("password", password);
  return onePiece.post("/epp/login", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const GetEppProductApi = () => {
  return onePiece.get("/epp/product-list", {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
      role: `${secureLocalStorage.getItem("role")}`,
    },
  });
};

export const GetEppEmployesApi = () => {
  return onePiece.get("/epp/employees", {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
      role: `${secureLocalStorage.getItem("role")}`,
    },
  });
};

export const GenerateEmployeePassword = (name: string, email: string) => {
  return onePiece.post(
    "/epp/generate-password",
    { email, name },
    {
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
        code: `${secureLocalStorage.getItem("code")}`,
        email: `${secureLocalStorage.getItem("email")}`,
        name: `${secureLocalStorage.getItem("name")}`,
        platform: `${secureLocalStorage.getItem("platform")}`,
        role: `${secureLocalStorage.getItem("role")}`,
      },
    },
  );
};

export const AddEmployeesApi = (file: File) => {
  const formData = new FormData();
  formData.append("employees", file);
  return onePiece.post("/epp/add-employee", formData, {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
      role: `${secureLocalStorage.getItem("role")}`,
    },
  });
};

export const CreateOrderApi = (
  product_id: string,
  product_specification: string,
  rental_amount: number,
  variant: string,
  quantity: number = 1, // Default value for quantity if not provided
) => {
  // Create a new FormData object and append all required fields
  const formData = new FormData();
  formData.append("product_id", product_id);
  formData.append("product_specification", product_specification);
  formData.append("rental_amount", rental_amount.toString()); // Convert number to string
  formData.append("variant", variant);
  formData.append("quantity", quantity.toString()); // Convert number to string

  // Make the POST request to the API endpoint
  return onePiece.post("/epp/add-order", formData, {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
      role: `${secureLocalStorage.getItem("role")}`,
      user_id: `${secureLocalStorage.getItem("user_id")}`,
    },
  });
};

export const GetEppOrdersApi = () => {
  return onePiece.get("/epp/get-order", {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
      role: `${secureLocalStorage.getItem("role")}`,
      user_id: `${secureLocalStorage.getItem("user_id")}`,
    },
  });
};

export const UpdateEppOrderStatusApi = (order_id: string, status: string) => {
  // Create a new FormData object and append all required fields
  const formData = new FormData();
  formData.append("id", order_id);
  formData.append("status", status);

  // Make the POST request to the API endpoint
  return onePiece.post("/epp/updatestatus", formData, {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
      role: `${secureLocalStorage.getItem("role")}`,
      user_id: `${secureLocalStorage.getItem("user_id")}`,
    },
  });
};

export const GetEppCartApi = () => {
  return onePiece.get("/epp/cart", {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
      role: `${secureLocalStorage.getItem("role")}`,
      user_id: `${secureLocalStorage.getItem("user_id")}`,
    },
  });
};

export const PostPlaceOrderApi = (vendor_id: string, order: string[]) => {
  return onePiece.post(
    "/epp/place-order",
    { vendor_id, order },
    {
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
        code: `${secureLocalStorage.getItem("code")}`,
        email: `${secureLocalStorage.getItem("email")}`,
        name: `${secureLocalStorage.getItem("name")}`,
        platform: `${secureLocalStorage.getItem("platform")}`,
        role: `${secureLocalStorage.getItem("role")}`,
        user_id: `${secureLocalStorage.getItem("user_id")}`,
      },
    },
  );
};

export const GetOrdersApi = () => {
  return onePiece.get("/epp/orders", {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
      role: `${secureLocalStorage.getItem("role")}`,
      user_id: `${secureLocalStorage.getItem("user_id")}`,
    },
  });
};

// export const GetVendorClientApi = () => {
//   return onePiece.get("/epp/vendor/client-list", {
//     headers: {
//       Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
//       code: `${secureLocalStorage.getItem("code")}`,
//       email: `${secureLocalStorage.getItem("email")}`,
//       name: `${secureLocalStorage.getItem("name")}`,
//       platform: `${secureLocalStorage.getItem("platform")}`,
//       role: `${secureLocalStorage.getItem("role")}`,
//       user_id: `${secureLocalStorage.getItem("user_id")}`,
//     },
//   });
// };

export const GetVendorClientApi = () => {
  return salesforce.post(
    "/clientListByVendor",
    {
      "Vendor ID": [`${secureLocalStorage.getItem("code")}`],
    },
    {
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem("epp_access_token")}`,
        // code: `${secureLocalStorage.getItem("code")}`,
      },
    },
  );
};

export const GetVendorCompanyWiseOrdersApi = (company_code: string) => {
  return onePiece.post(
    "/epp/orders-list",
    { company_code },
    {
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
        code: `${secureLocalStorage.getItem("code")}`,
        email: `${secureLocalStorage.getItem("email")}`,
        name: `${secureLocalStorage.getItem("name")}`,
        platform: `${secureLocalStorage.getItem("platform")}`,
        role: `${secureLocalStorage.getItem("role")}`,
        user_id: `${secureLocalStorage.getItem("user_id")}`,
      },
    },
  );
};
export const GetVendorSalesforceCompanyWiseOrdersApi = (
  company_code: string,
) => {
  return salesforce.post(
    "/poListByClientAndVendor",
    {
      "Vendor ID": [`${secureLocalStorage.getItem("code")}`],
      "Client ID": [`${company_code}`],
    },
    {
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem("epp_access_token")}`,
      },
    },
  );
};

export const GetVendorList = () => {
  return onePiece.get("/epp/vendor-list", {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
      role: `${secureLocalStorage.getItem("role")}`,
      user_id: `${secureLocalStorage.getItem("user_id")}`,
    },
  });
};

export const GetOrderDetailApi = (order_id: string) => {
  return onePiece.get(`/epp/order-detail/${order_id}`, {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
      role: `${secureLocalStorage.getItem("role")}`,
      user_id: `${secureLocalStorage.getItem("user_id")}`,
    },
  });
};

export const AddClientApi = (name: string, code: string, file: File) => {
  const formData = new FormData();
  formData.append("inventory", file);
  formData.append("name", name);
  formData.append("code", code);
  return baki.post("/epp/upload-product-details", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
      role: `${secureLocalStorage.getItem("role")}`,
      user_id: `${secureLocalStorage.getItem("user_id")}`,
    },
  });
};

export const GetOrderProductDetailApi = () => {
  return onePiece.get("/epp/product-details", {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
      role: `${secureLocalStorage.getItem("role")}`,
      user_id: `${secureLocalStorage.getItem("user_id")}`,
    },
  });
};

//level update api
export const CreateLevelApi = (
  l1_start: string,
  l1_end: string,
  l2_start: string,
  l2_end: string,
  l3_start: string,
  l3_end: string,
  l4_start: string,
  l4_end: string,
  l5_start: string,
  l5_end: string,
) => {
  const formData = new FormData();
  formData.append("l1_start", l1_start);
  formData.append("l1_end", l1_end);
  formData.append("l2_start", l2_start);
  formData.append("l2_end", l2_end);
  formData.append("l3_start", l3_start);
  formData.append("l3_end", l3_end);
  formData.append("l4_start", l4_start);
  formData.append("l4_end", l4_end);
  formData.append("l5_start", l5_start);
  formData.append("l5_end", l5_end);

  return onePiece.post("/epp/approval-code", formData, {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
      role: `${secureLocalStorage.getItem("role")}`,
      user_id: `${secureLocalStorage.getItem("user_id")}`,
    },
  });
};

export const GetLevelApi = () => {
  return onePiece.get("/epp/approval-code", {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
      role: `${secureLocalStorage.getItem("role")}`,
      user_id: `${secureLocalStorage.getItem("user_id")}`,
    },
  });
};

//epp address
export const AddEppAddressApi = (file: File) => {
  const formData = new FormData();
  formData.append("addresses", file);
  return onePiece.post("/epp/add-address-code", formData, {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
      role: `${secureLocalStorage.getItem("role")}`,
    },
  });
};

export const GetEppAddressApi = () => {
  return onePiece.get("/epp/address-code", {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
      role: `${secureLocalStorage.getItem("role")}`,
      user_id: `${secureLocalStorage.getItem("user_id")}`,
    },
  });
};

//vendor bank
export const AddVendorBankApi = (
  bank_name: string,
  account_number: string,
  ifsc_code: string,
  cancelled_cheque: File,
) => {
  const formData = new FormData();
  formData.append("cancelled_cheque", cancelled_cheque);
  formData.append("ifsc_code", ifsc_code);
  formData.append("account_number", account_number);
  formData.append("bank_name", bank_name);

  return baki.post("/epp/vendor/bank-detail-upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
    },
  });
};

export const VendorPanVerificationApi = (
  vendor_type: string,
  pan: string,
  udyam_number: string,
  aadhar_number: string,
) => {
  return onePiece.post(
    "/epp/vendor/pan-detail",
    { vendor_type, pan, udyam_number, aadhar_number },
    {
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
        code: `${secureLocalStorage.getItem("code")}`,
        email: `${secureLocalStorage.getItem("email")}`,
        name: `${secureLocalStorage.getItem("name")}`,
        platform: `${secureLocalStorage.getItem("platform")}`,
        role: `${secureLocalStorage.getItem("role")}`,
      },
    },
  );
};
export const GetVendorAddressListApi = () => {
  return onePiece.get("/epp/vendor/address-detail", {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
      role: `${secureLocalStorage.getItem("role")}`,
    },
  });
};

export const GetVendorInfoApi = () => {
  return onePiece.get("/epp/vendor/vendor-detail", {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
      role: `${secureLocalStorage.getItem("role")}`,
    },
  });
};

//epp vendor inventory
export const AddEppVendorInventoryApi = (file: File, po_number?: string) => {
  const formData = new FormData();
  formData.append("vendor_inventory", file);
  if (po_number) {
    formData.append("po_number", po_number);
  }
  return onePiece.post("/epp/vendor/add-inventory", formData, {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
      role: `${secureLocalStorage.getItem("role")}`,
    },
  });
};

export const GetEppVendorPowiseInventoryApi = (po_number: string) => {
  const formData = new FormData();
  formData.append("poNumber", po_number);

  return onePiece.post(`/epp/vendor/inventory`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
      role: `${secureLocalStorage.getItem("role")}`,
      user_id: `${secureLocalStorage.getItem("user_id")}`,
    },
  });
};

export const GetEppEmployesActiveRentalDevicesApi = () => {
  return onePiece.get("/epp/employee/active-rentals-list", {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
      role: `${secureLocalStorage.getItem("role")}`,
    },
  });
};

export const AddVendorPoInvoiceApi = (
  company_code: string,
  po_number: string,
  po_invoice: File,
) => {
  const formData = new FormData();
  formData.append("po_invoice", po_invoice);
  formData.append("po_number", po_number);
  formData.append("company_code", company_code);

  return baki.post("/epp/vendor/invoice-po-upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
    },
  });
};

export const GetEppVendorPowiseInvoicesApi = (po_number: string) => {
  const formData = new FormData();
  formData.append("poNumber", po_number);

  return onePiece.post(`/vendor-powiseinvoice-details`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      code: `${secureLocalStorage.getItem("code")}`,
      email: `${secureLocalStorage.getItem("email")}`,
      name: `${secureLocalStorage.getItem("name")}`,
      platform: `${secureLocalStorage.getItem("platform")}`,
      role: `${secureLocalStorage.getItem("role")}`,
      user_id: `${secureLocalStorage.getItem("user_id")}`,
    },
  });
};

export const GetVendorSalesforcePoOrdersApi = (po_number: string) => {
  return salesforce.post(
    "/POItemsAPI",
    {
      po_number: [`${po_number}`],
    },
    {
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem("epp_access_token")}`,
      },
    },
  );
};
