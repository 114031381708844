import * as React from "react";
import Spacer from "../../../components/ui/Spacer.tsx";
import Main from "../../../components/ui/sidebar/Main.tsx";
import PoUploadProductForm from "../../../../src/components/pages/epp/vendor/view-orders/PoUploadProductForm.tsx";

export default function PowiseUploadProduct() {
  return (
    <Main>
      <div className="text-black bg-pageBackground px-10 min-h-screen">
        <Spacer size="sm" />
        <PoUploadProductForm />
      </div>
    </Main>
  );
}
