import * as React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { CiSaveDown2 } from "react-icons/ci";
import { exportToExcel } from "../../../../../../utils/utils.ts";
import { AddVendorPoInvoiceApi } from "../../../../../../apis.ts";
import Spacer from "../../../../../ui/Spacer.tsx";
import Button from "../../../../../ui/Button.tsx";
import FileInput from "../../../../../ui/FileInput.tsx";
import Row from "../../../../../ui/Row.tsx";
import useApi from "../../../../../../hooks/useApi.tsx";
import Main from "../../../../../../../src/components/ui/sidebar/Main.tsx";
import BreadcrumbComponent from "../../../../../../../src/components/ui/Breadcrumb.tsx";

const INTIAL_VALUES = {
  po_invoice: null as File | null,
};

export default function PoInvoiceForm() {
  const { makeApiCall } = useApi();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const companyCode = searchParams.get("company_code") ?? "";
  const poNumber = searchParams.get("po_number") ?? "";

  const validationSchema = Yup.object().shape({
    po_invoice: Yup.mixed().required("File is required"),
  });

  const downloadCSV = React.useCallback(() => {
    const headers = [
      {
        "Company Name": "",
        "Address Line 1": "",
        "Address Line 2": "",
        City: "",
        State: "",
        PIN: "",
        "GST Number": "",
      },
    ];
    return exportToExcel(headers, "address-example-csv");
  }, []);
  const navigateToUploadProductDetails = React.useCallback(() => {
    return navigate(-1);
  }, [navigate]);

  const handleSubmit = React.useCallback(
    ({ po_invoice }: typeof INTIAL_VALUES) => {
      return makeApiCall(
        AddVendorPoInvoiceApi(companyCode, poNumber, po_invoice!),
      )
        .then((response) => {
          navigateToUploadProductDetails();
          console.log(response, "upload response");
          toast.success("Invoice added succesfully");
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.message || "Invoice addition failed",
          );
        });
    },
    [companyCode, makeApiCall, navigateToUploadProductDetails, poNumber],
  );
  return (
    <Main>
      <div className="text-black bg-pageBackground  px-10 min-h-screen">
        <div className="flex flex-col">
          <BreadcrumbComponent
            locations={[
              { name: "Client", path: "/client" },
              { name: companyCode, path: "/client" },
              { name: poNumber, path: "/po-upload-product" },
            ]}
          />
          <div className="flex flex-row justify-between items-center w-[100%]">
            <p className="text-black text-lg leading-8 font-roboto font-normal">
              Po Invoices
            </p>
            <Button
              color="default"
              variant="bordered"
              startContent={<CiSaveDown2 size={18} />}
              className="font-roboto"
              size="sm"
              radius="sm"
              onClick={downloadCSV}
            >
              Download
            </Button>
          </div>
          <Spacer size="xs" />
          <Formik
            initialValues={INTIAL_VALUES}
            onSubmit={handleSubmit}
            validateOnBlur
            validateOnChange
            validationSchema={validationSchema}
            enableReinitialize
          >
            <Form>
              <FileInput name="po_invoice" type="dropzone" />
              <Spacer size="sm" />
              <Row justifyContent="center">
                <Button type="submit">Submit</Button>
              </Row>
            </Form>
          </Formik>
        </div>
      </div>
    </Main>
  );
}
