import VendorOrdersList from "../../../../src/components/pages/epp/vendor/view-orders/VendorOrdersList";
import Main from "../../../../src/components/ui/sidebar/Main";
import Spacer from "../../../../src/components/ui/Spacer";
import * as React from "react";

export default function VendorOrders() {
  return (
    <Main>
      <div className="text-black bg-pageBackground px-10 min-h-screen">
        <Spacer size="sm" />
        <VendorOrdersList />
      </div>
    </Main>
  );
}
