import { GetEppVendorPowiseInvoicesApi } from "../../../apis.ts";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import useApi from "../../../hooks/useApi.tsx";
import { VendorInvoiceType } from "../../../types.ts";
import VendorPoinvoicesList from "../../../components/pages/epp/vendor/vendor-poinvoices/List.tsx";
import Main from "../../../components/ui/sidebar/Main.tsx";

const VendorInvoice: React.FC = () => {
  const { makeApiCall } = useApi();
  const navigate = useNavigate();
  const [invoices, setInvoice] = React.useState<VendorInvoiceType[]>([]);
  const [loading, setLoading] = React.useState(false);
  const navigateToAddAddress = React.useCallback(() => {
    navigate("/dashboard/add-inventory");
  }, [navigate]);

  React.useEffect(() => {
    setLoading(true);
    makeApiCall(GetEppVendorPowiseInvoicesApi(""))
      .then((response) => {
        setLoading(false);
        if (response !== undefined) {
          console.log(response, "invoice vendor API response");
          setInvoice(response?.data);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false);
      });
  }, [makeApiCall]);

  return (
    <>
      <Main>
        <div className="text-black bg-pageBackground px-10 min-h-screen">
          <VendorPoinvoicesList
            invoices={invoices}
            loading={loading}
            navigateToAddAddress={navigateToAddAddress}
          />
        </div>
      </Main>
    </>
  );
};

export default VendorInvoice;
