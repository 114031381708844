import React from "react";
import { Route, Routes } from "react-router";

import Dashboard from "./pages/client/Dashboard/Dashboard.tsx";
import RentalSchedule from "./pages/client/RentalSchedule/RentalSchedule.tsx";
import Inventory from "./pages/client/Inventory/Inventory.tsx";
import Invoices from "./pages/client/Invoices/Invoices.tsx";
import Support from "./pages/client/Support/Support.tsx";
import Contact from "./pages/client/Contact/Contact.tsx";
import Documents from "./pages/client/Documents/Documents.tsx";
import ViewInventory from "./pages/client/Inventory/ViewInventory/ViewInventory.tsx";
import NewBusiness from "./pages/client/NewBusiness/NewBusiness.tsx";
import Automation from "./pages/client/Automation/Automation.tsx";
import DashboardEpp from "./pages/epp/Dashboard/Dashboard.tsx";
import EmployeeDirectory from "./pages/client/EmployeeDirectory/EmployeeDirectory.tsx";
import AddEmployees from "./pages/client/EmployeeDirectory/AddEmployees.tsx";
import Products from "./pages/epp/Products/Products.tsx";
import ActiveRentals from "./pages/epp/ActiveRentals/ActiveRentals.tsx";
import ManageOrders from "./pages/epp/ManageOrders/ManageOrders.tsx";
import Orders from "./pages/epp/Employer/Orders/Orders.tsx";
import PDFViewerPage from "./pages/client/Pdfviewer/PDFViewerPage.tsx";
import Client from "./pages/epp/Vendor/Client.tsx";
import ViewOrders from "./pages/epp/Vendor/ViewOrders.tsx";
import OrderDetail from "./pages/epp/Vendor/OrderDetail.tsx";
import UploadProductDetail from "./pages/epp/Vendor/UploadProductDetail.tsx";
import Login from "./pages/client/login/Login.tsx";
import EppLogin from "./pages/epp/login/EppLogin.tsx";
import AdminSettings from "./pages/epp/AdminSettings/AdminSettings.tsx";
import AddAddress from "./pages/epp/AdminSettings/AddAddress.tsx";
import VendorOrders from "./pages/epp/Vendor/VendorOrders.tsx";
import VendorProfile from "./pages/epp/Vendor/VendorProfile.tsx";
import AddInventory from "./pages/epp/Vendor/AddInventory.tsx";
import VendorInventory from "./pages/epp/Vendor/VendorInventory.tsx";
import EmployeeActiveRentals from "./pages/epp/Employee/ActiveRentals.tsx";
import PoDetail from "./pages/epp/Vendor/PoDetail.tsx";
import PowiseUploadProduct from "./pages/epp/Vendor/PowiseUploadProduct.tsx";
import OrderDetailsView from "./pages/epp/Employer/Orders/order-details/View.tsx";
import VendorInvoice from "./pages/epp/Vendor/VendorInvoice.tsx";
import PoInvoiceForm from "./components/pages/epp/vendor/view-orders/invoice-upload/PoInvoiceForm.tsx";

const DashPaths = () => (
  <Routes>
    <Route path="/login" element={<Login />} />
    <Route path="/epp/login" element={<EppLogin />} />
    <Route path="/dashboard" element={<Dashboard />} />
    <Route path="/dashboard/rentalschedule" element={<RentalSchedule />} />
    <Route path="/dashboard/inventory" element={<Inventory />} />
    <Route path="/dashboard/invoices" element={<Invoices />} />
    <Route path="/dashboard/support" element={<Support />} />
    <Route path="/dashboard/contact" element={<Contact />} />
    <Route path="/dashboard/documents" element={<Documents />} />
    <Route path="/dashboard/inventorylist" element={<ViewInventory />} />
    <Route path="/dashboard/newbusiness" element={<NewBusiness />} />
    <Route path="/dashboard/automation" element={<Automation />} />
    <Route path="/dashboard/pdfview" element={<PDFViewerPage />} />

    {/* eppPaths */}

    <Route path="/epphome" element={<DashboardEpp />} />
    <Route
      path="/dashboard/employee-directory"
      element={<EmployeeDirectory />}
    />
    <Route path="/dashboard/add-employees" element={<AddEmployees />} />
    <Route path="/dashboard/orders" element={<Orders />} />
    <Route path="/dashboard/order-details" element={<OrderDetailsView />} />

    {/* epp employee paths */}

    <Route path="/dashboard/products" element={<Products />} />
    <Route path="/dashboard/activerentals" element={<ActiveRentals />} />
    <Route path="/dashboard/managerentals" element={<ManageOrders />} />
    <Route path="/dashboard/admin-settings" element={<AdminSettings />} />
    <Route path="/dashboard/add-address" element={<AddAddress />} />
    <Route path="/dashboard/add-inventory" element={<AddInventory />} />
    <Route
      path="/dashboard/employee-active-rentals"
      element={<EmployeeActiveRentals />}
    />

    {/* epp vendor paths */}
    <Route path="/client" element={<Client />} />
    <Route path="/client/view-orders" element={<ViewOrders />} />
    <Route path="/client/view-vendor-orders" element={<VendorOrders />} />
    <Route path="/client/vendor-profile" element={<VendorProfile />} />
    <Route path="/client/vendor-inventory" element={<VendorInventory />} />

    <Route path="/client/view-order-detail" element={<OrderDetail />} />
    <Route path="/client/po-detail" element={<PoDetail />} />

    <Route
      path="/client/upload-product-details"
      element={<UploadProductDetail />}
    />
    <Route path="/client/po-upload-product" element={<PowiseUploadProduct />} />
    <Route path="/client/vendor-invoice" element={<VendorInvoice />} />
    <Route path="/client/po-upload-invoice" element={<PoInvoiceForm />} />
  </Routes>
);

export default DashPaths;
