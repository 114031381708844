import * as React from "react";
import { OrderType } from "../../../../../types";
import { Button } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

interface Props {
  item: OrderType;
}

export default function Action({ item }: Props) {
  const navigate = useNavigate();

  const onViewDetails = React.useCallback(() => {
    return navigate(
      `/dashboard/order-details?order_id=${encodeURIComponent(item?.order_id ?? "")}`,
    );
  }, [item?.order_id, navigate]);

  return (
    <div className="flex flex-row justify-end gap-x-3 2xl:gap-x-4 whitespace-nowrap">
      <Button
        onClick={onViewDetails}
        className="text-textColorGrey  cursor-pointer font-roboto font-normal"
      >
        View Details
      </Button>
    </div>
  );
}
