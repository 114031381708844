import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Spacer from "../../../ui/Spacer";
import Input from "../../../ui/Input";
import Row from "../../../ui/Row";
import Button from "../../../ui/Button";
import FileInput from "../../../../../src/components/ui/FileInput";
import useApi from "../../../../../src/hooks/useApi";
import { AddVendorBankApi } from "../../../../../src/apis";
import { toast } from "react-toastify";

const BankVerification = () => {
  const { makeApiCall } = useApi();

  const [loading, setLoading] = React.useState(false);

  const initial_values = {
    bank_name: "",
    account_number: "",
    ifsc_code: "",
    cancelled_cheque: null as File | null,
  };

  const validationSchema = Yup.object().shape({
    bank_name: Yup.string().required("Bank name is required"),
    account_number: Yup.string().required("Account number is required"),
    ifsc_code: Yup.string().required("IFSC code is required"),
    cancelled_cheque: Yup.mixed().required("Cancelled cheque is required"),
  });

  const handleSubmit = React.useCallback(
    ({
      bank_name,
      account_number,
      ifsc_code,
      cancelled_cheque,
    }: typeof initial_values) => {
      console.log("Form Submitted", {
        bank_name,
        account_number,
        ifsc_code,
        cancelled_cheque,
      });

      setLoading(true);
      return makeApiCall(
        AddVendorBankApi(
          bank_name,
          account_number,
          ifsc_code,
          cancelled_cheque!,
        ),
      )
        .then((response) => {
          console.log("Success", response);
          toast.success("Bank added succesfully");
          return true;
        })
        .catch((error) => {
          toast.error("Bank addition failed");
          console.error(error);
          return false;
        })
        .finally(() => setLoading(false));
    },
    [makeApiCall],
  );

  return (
    <section className="table-wrapper">
      <div className="py-8 px-4 mx-auto max-w-2xl">
        <Formik
          initialValues={initial_values}
          onSubmit={handleSubmit}
          validateOnBlur
          validateOnChange
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ setFieldValue }) => (
            <Form>
              <Spacer orientation="horizontal" size="xs" />
              <Spacer size="xs" />
              <Input
                label="Bank Name"
                placeholder="Enter bank name"
                name="bank_name"
              />
              <Spacer size="xs" />
              <Input
                label="Account Number"
                placeholder="Enter account number"
                name="account_number"
              />
              <Spacer size="xs" />
              <Input
                label="IFSC Code"
                placeholder="Enter IFSC code"
                name="ifsc_code"
              />
              <Spacer size="xs" />
              <FileInput
                label="Cancelled cheque"
                name="cancelled_cheque"
                type="dropzone"
                accept=".jpg,.png,.pdf"
              />
              <Row justifyContent="center">
                <Button isLoading={loading} color="primary">
                  Submit
                </Button>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default BankVerification;
