import * as React from "react";
import Row from "../../../../components/ui/Row.tsx";
import {
  Button,
  Selection,
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  TableCell,
  SortDescriptor,
  Spinner,
  Pagination,
  Input,
} from "@nextui-org/react";
// import RenderColumns from "./RenderColumns.tsx";
import { AssetInventoryType, SelectType } from "../../../../types.ts";
import Spacer from "../../../../components/ui/Spacer.tsx";
import { CiFilter, CiSearch } from "react-icons/ci";
import Select from "../../../../components/ui/Select.tsx";
import { exportToExcel } from "../../../../utils/utils.ts";
import RenderColumns from "./RenderColumn.tsx";

interface Props {
  deviceInfo: AssetInventoryType[];
  loading?: boolean;
}

const COLUMNSADMIN = [
  {
    name: "Sr No.",
    key: "sr_no",
  },
  {
    name: "Product",
    key: "category",
  },
  {
    name: "Brand",
    key: "brand",
  },
  {
    name: "Model",
    key: "model",
  },
  {
    name: "Serial Number",
    key: "serial_number",
  },
  {
    name: "Location",
    key: "delivery_address",
  },
  {
    name: "Status",
    key: "status",
  },
  {
    name: "Monthly Rent",
    key: "rent",
  },
];

export default function EmployeeActiveRentalsList({
  deviceInfo,
  loading = false,
}: Props) {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const scheduleNumberParam = params.get("schedule_number");
  const [filterValue, setFilterValue] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [showFilter, setShowFilter] = React.useState(false);
  const [selectedState, setSelectedState] = React.useState("all");
  const [selectedBrand, setSelectedBrand] = React.useState("all");

  const [tempselectedState, setTempSelectedState] = React.useState("all");
  const [tempselectedBrand, setTempSelectedBrand] = React.useState("all");

  const [dropdownFilter, setDropdownFilters] = React.useState<SelectType[]>([]);
  const [dropdownFilterBrands, setDropdownFiltersBrands] = React.useState<
    SelectType[]
  >([]);

  const [sortDescriptor, setSortDescriptor] = React.useState<SortDescriptor>({
    column: "serial_number",
    direction: "ascending",
  });

  const hasSearchFilter = Boolean(filterValue);
  const [page, setPage] = React.useState(1);

  const downloadCsvDetails = React.useCallback((item: AssetInventoryType) => {
    const mappedDetails = {
      adp: item.ADP,
      adapter_charger_cable: item.AdapterChargerCable,
      asset_category: item.AssetCategory,
      asset_details_description: item.AssetDetailsDescription,
      branch: item.Branch,
      brand: item.Brand,
      cpu: item.CPU,
      company_code: item.CompanyCode,
      created_at: item.CreatedAt,
      deleted_at: item.DeletedAt,
      delivery_address: item.DeliveryAddress,
      end_date: item.EndDate,
      from_date: item.FromDate,
      generation: item.Generation,
      hsn_code: item.HSNCode,
      id: item.ID,
      identifier: item.Identifier,
      invoice_date: item.InvoiceDate,
      invoice_no: item.InvoiceNo,
      model_name: item.ModelName,
      name_of_vendor: item.NameofVendor,
      original_schedule_number: item.OriginalScheduleNumber,
      processor: item.Processor,
      purchase_date: item.PurchaseDate,
      qty: item.QTY,
      ram: item.RAM,
      schedule_amount: item.ScheduleAmount,
      schedule_number: item.ScheduleNumber,
      screen: item.Screen,
      serial_number: item.SerialNumber,
      state: item.State,
      storage: item.Storage,
      tenure: item.Tenure,
      tranche_no: item.TrancheNo,
      updated_at: item.UpdatedAt,
      warranty_end_date: item.WarrantyEndDate,
      warranty_start_date: item.WarrantyStartDate,
    };

    return exportToExcel([mappedDetails], item?.ScheduleNumber);
  }, []);

  const downloadShipmentDetails = React.useCallback(() => {
    const mappedDetails = deviceInfo?.map?.((detail: AssetInventoryType) => {
      return {
        adp: detail.ADP,
        adapter_charger_cable: detail.AdapterChargerCable,
        asset_category: detail.AssetCategory,
        asset_details_description: detail.AssetDetailsDescription,
        branch: detail.Branch,
        brand: detail.Brand,
        cpu: detail.CPU,
        company_code: detail.CompanyCode,
        created_at: detail.CreatedAt,
        deleted_at: detail.DeletedAt,
        delivery_address: detail.DeliveryAddress,
        end_date: detail.EndDate,
        from_date: detail.FromDate,
        generation: detail.Generation,
        hsn_code: detail.HSNCode,
        id: detail.ID,
        identifier: detail.Identifier,
        invoice_date: detail.InvoiceDate,
        invoice_no: detail.InvoiceNo,
        model_name: detail.ModelName,
        name_of_vendor: detail.NameofVendor,
        original_schedule_number: detail.OriginalScheduleNumber,
        processor: detail.Processor,
        purchase_date: detail.PurchaseDate,
        qty: detail.QTY,
        ram: detail.RAM,
        schedule_amount: detail.ScheduleAmount,
        schedule_number: detail.ScheduleNumber,
        screen: detail.Screen,
        serial_number: detail.SerialNumber,
        state: detail.State,
        storage: detail.Storage,
        tenure: detail.Tenure,
        tranche_no: detail.TrancheNo,
        updated_at: detail.UpdatedAt,
        warranty_end_date: detail.WarrantyEndDate,
        warranty_start_date: detail.WarrantyStartDate,
      };
    });
    return exportToExcel(
      mappedDetails,
      scheduleNumberParam || "All Device Info",
    );
  }, [deviceInfo, scheduleNumberParam]);

  React.useEffect(() => {
    const dropdownOptionsData: SelectType[] = deviceInfo
      ?.map?.((item: AssetInventoryType) => ({
        value: item.AssetCategory,
        label: item.AssetCategory,
      }))
      .filter(
        (option, index, self) =>
          index === self.findIndex((o) => o.value === option.value),
      );
    dropdownOptionsData.unshift({ value: "all", label: "All" });
    setDropdownFilters(dropdownOptionsData);

    const dropdownOptionsDataBrand: SelectType[] = deviceInfo
      ?.map?.((item: AssetInventoryType) => ({
        value: item.Brand,
        label: item.Brand,
      }))
      .filter(
        (option, index, self) =>
          index === self.findIndex((o) => o.value === option.value),
      );
    dropdownOptionsDataBrand.unshift({ value: "all", label: "All" });
    setDropdownFiltersBrands(dropdownOptionsDataBrand);
  }, [deviceInfo]);

  const pages = React.useMemo(() => {
    if (deviceInfo.length === 0) {
      return 1;
    }
    return Math.ceil((deviceInfo?.length ?? 1) / rowsPerPage);
  }, [deviceInfo, rowsPerPage]);

  const [selectedKeys, setSelectedKeys] = React.useState<Selection>(
    new Set([]),
  );

  const onSearchChange = React.useCallback(
    (value: React.SetStateAction<string>) => {
      if (value) {
        setFilterValue(value);
        setPage(1);
      } else {
        setFilterValue("");
      }
    },
    [],
  );

  const filteredItems = React.useMemo(() => {
    let filteredUsers = [...deviceInfo];
    if (hasSearchFilter) {
      filteredUsers = filteredUsers.filter(
        (user) =>
          user.SerialNumber.toLowerCase().includes(filterValue.toLowerCase()) ||
          user.State.toLowerCase().includes(filterValue.toLowerCase()) ||
          user.Brand.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }

    if (selectedState !== "all" && selectedState) {
      filteredUsers = filteredUsers.filter(
        (user) => user.AssetCategory === selectedState,
      );
    }
    if (selectedBrand !== "all" && selectedBrand) {
      filteredUsers = filteredUsers.filter(
        (user) => user.Brand === selectedBrand,
      );
    }

    return filteredUsers;
  }, [deviceInfo, filterValue, hasSearchFilter, selectedBrand, selectedState]);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return filteredItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage]);

  const sortedItems = React.useMemo(() => {
    return [...items].sort((a: AssetInventoryType, b: AssetInventoryType) => {
      const first = a[
        sortDescriptor.column as keyof AssetInventoryType
      ] as number;
      const second = b[
        sortDescriptor.column as keyof AssetInventoryType
      ] as number;
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);

  const onRowsPerPageChange = React.useCallback(
    (e: { target: { value: any } }) => {
      setRowsPerPage(Number(e.target.value));
      setPage(1);
    },
    [],
  );

  const renderCell = React.useCallback(
    (device: AssetInventoryType, columnKey: React.Key) => {
      const index = deviceInfo.map((object) => object.ID).indexOf(device.ID);
      return (
        <RenderColumns
          index={index}
          asset={device}
          columnKey={columnKey}
          handleDownloadCsv={downloadCsvDetails}
        />
      );
    },
    [deviceInfo, downloadCsvDetails],
  );

  const handleStateSelect = (value: string) => {
    setTempSelectedState(value);
  };

  const handleBrandSelect = (value: string) => {
    setTempSelectedBrand(value);
  };
  const topContent = React.useMemo(() => {
    return (
      <div className="relative flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end my-2">
          <Input
            isClearable
            className="focus:outline-none focus:border-none"
            classNames={{
              base: "w-full sm:max-w-[44%] focus:outline-none focus:border-none",
              inputWrapper:
                "border-0 focus:border-0 focus:outline-none focus:border-none",
              input: "border-0 focus:outline-none focus:border-none",
            }}
            placeholder="Search by product, brand, model..."
            size="sm"
            startContent={<CiSearch />}
            value={filterValue}
            onClear={() => {
              setFilterValue("");
              setSelectedState("all");
              setSelectedBrand("all");
            }}
            onValueChange={onSearchChange}
          />

          <div className="flex gap-3">
            <Row>
              <Button
                color="default"
                variant="bordered"
                startContent={<CiFilter size={18} />}
                className="font-roboto"
                size="sm"
                radius="sm"
                onClick={() => {
                  setShowFilter(!showFilter);
                  setSelectedState("all");
                  setSelectedBrand("all");
                }}
              >
                Filter
              </Button>
            </Row>
          </div>
        </div>
        {showFilter && (
          <div className="absolute top-full mt-2 right-0 w-[20%] px-6 py-4 rounded shadow-xl z-10 bg-white">
            <p className="text-black text-base leading-8 font-roboto font-medium mt-[4%] bg-white">
              Filter
            </p>
            <div className="w-full h-5" />

            <Select
              name="filter"
              item={dropdownFilter}
              label="Product"
              placeholder="Product"
              onSelect={handleStateSelect}
              className="font-roboto text-xl font-light"
            />
            <Spacer size="xs" />
            <Select
              name="filter"
              item={dropdownFilterBrands}
              label="Brand"
              placeholder="Brand"
              onSelect={handleBrandSelect}
              className="font-roboto text-xl font-light"
            />
            <div>
              <Row>
                <Button
                  color="default"
                  className="font-roboto font-light text-sm bg-buttonprimary text-white w-[100%] mt-4"
                  size="md"
                  radius="sm"
                  onClick={() => {
                    setSelectedState(tempselectedState);
                    setSelectedBrand(tempselectedBrand);
                    setShowFilter(false);
                  }}
                >
                  Filter
                </Button>
              </Row>{" "}
            </div>
          </div>
        )}
      </div>
    );
  }, [
    filterValue,
    onSearchChange,
    showFilter,
    dropdownFilter,
    dropdownFilterBrands,
    tempselectedState,
    tempselectedBrand,
  ]);

  const classNames = React.useMemo(
    () => ({
      th: [
        "bg-transparent",
        "text-tableHeaderColor",
        "border-b",
        "border-divider",
        "font-roboto",
        "font-regular",
        "text-sm",
      ],
      td: [
        "group-data-[first=true]:first:before:rounded-none",
        "group-data-[first=true]:last:before:rounded-none",
        "group-data-[middle=true]:before:rounded-none",
        "group-data-[last=true]:first:before:rounded-none",
        "group-data-[last=true]:last:before:rounded-none",
        "font-roboto",
        "font-normal",
        "text-textColorGrey",
      ],
      table: "min-h-[350px]",
      wrapper: "table-wrapper",
    }),
    [],
  );

  const bottomContent = React.useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-between items-center">
        <Pagination
          showControls
          classNames={{
            item: "bg-pageBackground w-6 h-6 min-w-4 font-roboto",
            cursor: "w-6 h-6 min-w-4 font-roboto",
          }}
          color="default"
          isDisabled={hasSearchFilter}
          page={page}
          total={pages}
          variant="light"
          onChange={setPage}
        />
        <div className="flex justify-between items-center">
          <label className="flex items-center text-small font-roboto text-black font-light ">
            Items per page :&nbsp;
            <select
              className="border-none shadow-sm outline-none text-default-400 text-small font-roboto font-light px-1 py-0 rounded-md"
              onChange={onRowsPerPageChange}
              defaultValue={"20"}
            >
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
          </label>
        </div>
      </div>
    );
  }, [hasSearchFilter, page, pages, onRowsPerPageChange]);

  return (
    <div className="flex flex-col">
      <div className=" flex flex-row justify-between items-center mt-[2%]">
        <p className="text-black text-lg leading-8 font-roboto font-normal">
          Active Rentals
        </p>
        <div>
          <div className="flex flex-row justify-end gap-x-3 2xl:gap-x-4 whitespace-nowrap">
            <Button onClick={downloadShipmentDetails}>Download</Button>
          </div>
        </div>
      </div>
      <Spacer size="xs" />
      <Table
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
        topContent={topContent}
        classNames={classNames}
        onSelectionChange={setSelectedKeys}
        bottomContent={bottomContent}
        bottomContentPlacement="inside"
      >
        <TableHeader columns={COLUMNSADMIN}>
          {(column) => (
            <TableColumn
              key={column.key}
              align={column.key === "action" ? "end" : "start"}
              width={column.key === "action" ? 100 : undefined}
              className="text-center"
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          emptyContent={!loading && "No rows to display."}
          items={sortedItems}
          isLoading={loading}
          loadingContent={<Spinner label="Loading..." />}
        >
          {(item) => (
            <TableRow
              key={`${item.SerialNumber}-${item.ID}`}
              className="cursor-pointer h-12"
            >
              {(columnKey) => (
                <TableCell className=" text-center  max-w-md">
                  {renderCell(item, columnKey)}
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
