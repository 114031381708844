import * as React from "react";
import {
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  TableCell,
  Spinner,
  Pagination,
  Input,
  Button,
  SortDescriptor,
} from "@nextui-org/react";
import { SelectType, VendorInvoiceType } from "../../../../../../../src/types";
import useApi from "../../../../../../hooks/useApi.tsx";
import { GetEppVendorPowiseInvoicesApi } from "../../../../../../apis.ts";
import { CiSearch } from "react-icons/ci";
import Row from "../../../../../ui/Row.tsx";
import Select from "../../../../../common/Select.tsx";
import Action from "./Action.tsx";
import { useNavigate } from "react-router-dom";

const COLUMNS = [
  {
    name: "Sr No.",
    key: "sr_no",
  },
  {
    name: "Invoice Number",
    key: "invoice_number",
  },
  {
    name: "Po Number",
    key: "po_number",
    sortable: true,
  },
  {
    name: "Company Code",
    key: "company_code",
  },
  {
    name: "Action",
    key: "action",
  },
];

interface Props {
  poNumber: string;
  navigateToUploadinvoiceDetails: () => void;
}
export default function PoInvoiceList({
  poNumber,
  navigateToUploadinvoiceDetails,
}: Props) {
  const [invoices, setInvoices] = React.useState<VendorInvoiceType[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [filterValue, setFilterValue] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [selectedState, setSelectedState] = React.useState("all");
  const [showFilter, setShowFilter] = React.useState(false);
  const [dropdownFilter, setDropdownFilters] = React.useState<SelectType[]>([]);
  const [tempSelectedState, setTempSelectedState] = React.useState("all");

  const [sortDescriptor, setSortDescriptor] = React.useState<SortDescriptor>({
    column: "name",
    direction: "ascending",
  });

  const { makeApiCall } = useApi();
  const navigate = useNavigate();

  React.useEffect(() => {
    setLoading(true);
    makeApiCall(GetEppVendorPowiseInvoicesApi(poNumber))
      .then((response) => {
        if (response !== undefined) {
          console.log(response, "Powise invoice response");
          setInvoices(response?.data);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [makeApiCall, poNumber]);
  React.useEffect(() => {
    const dropdownOptionsData: SelectType[] = invoices
      ?.map((item: VendorInvoiceType) => ({
        value: item.PoNumber,
        label: item.PoNumber,
      }))
      .filter(
        (option, index, self) =>
          index === self.findIndex((o) => o.value === option.value),
      );
    dropdownOptionsData.unshift({ value: "all", label: "All" });

    setDropdownFilters(dropdownOptionsData);
  }, [invoices]);

  const pages = React.useMemo(() => {
    if ((invoices?.length ?? 0) === 0) {
      return 1;
    }
    return Math.ceil((invoices?.length ?? 1) / rowsPerPage);
  }, [invoices?.length, rowsPerPage]);

  const hasSearchFilter = Boolean(filterValue);
  const [page, setPage] = React.useState(1);

  const filteredItems = React.useMemo(() => {
    let filteredUsers = [...invoices];

    if (hasSearchFilter) {
      filteredUsers = filteredUsers.filter(
        (user) =>
          user.PoNumber.toLowerCase().includes(filterValue.toLowerCase()) ||
          user.InvoiceNumber.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }

    if (selectedState !== "all" && selectedState) {
      filteredUsers = filteredUsers.filter(
        (user) => user.PoNumber === selectedState,
      );
    }
    return filteredUsers;
  }, [filterValue, hasSearchFilter, invoices, selectedState]);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return filteredItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage]);

  const onRowsPerPageChange = React.useCallback((e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
  }, []);

  const onSearchChange = React.useCallback((value) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const handleStateSelect = (value: string) => {
    setTempSelectedState(value);
  };

  const downloadDocument = React.useCallback(
    (name: string, url: string) => {
      navigate("/dashboard/pdfview", {
        state: { pdfUrl: url, pdfName: name },
      });
    },
    [navigate],
  );
  const renderCell = React.useCallback(
    (client: VendorInvoiceType, columnKey: React.Key) => {
      const index = invoices?.map((object) => object.ID).indexOf(client.ID);
      switch (columnKey) {
        case "sr_no":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">{index + 1}</p>
            </div>
          );
        case "po_number":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">{client.PoNumber}</p>
            </div>
          );
        case "company_code":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {client.CompanyCode}
              </p>
            </div>
          );
        case "vendor_code":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {client.VendorCode}
              </p>
            </div>
          );
        case "invoice_number":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {client.InvoiceNumber}
              </p>
            </div>
          );
        case "action":
          return (
            <div className="flex">
              <Action item={client} downloadInvoice={downloadDocument} />
            </div>
          );
      }
    },
    [downloadDocument, invoices],
  );

  const sortedItems = React.useMemo(() => {
    return [...items].sort((a: VendorInvoiceType, b: VendorInvoiceType) => {
      const first = a[sortDescriptor.column as keyof VendorInvoiceType];
      const second = b[sortDescriptor.column as keyof VendorInvoiceType];

      let cmp: number;

      if (typeof first === "number" && typeof second === "number") {
        cmp = first < second ? -1 : first > second ? 1 : 0;
      } else if (typeof first === "string" && typeof second === "string") {
        cmp = first.localeCompare(second);
      } else {
        cmp = 0;
      }
      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);

  const topContent = React.useMemo(() => {
    return (
      <div className="relative flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end my-2">
          <Input
            isClearable
            className="focus:outline-none focus:border-none"
            classNames={{
              base: "w-full sm:max-w-[44%] focus:outline-none focus:border-none",
              inputWrapper:
                "border-0 focus:border-0 focus:outline-none focus:border-none",
              input: "border-0 focus:outline-none focus:border-none",
            }}
            placeholder="Search by po or invoice number..."
            size="sm"
            startContent={<CiSearch />}
            value={filterValue}
            onClear={() => {
              setFilterValue("");
              setSelectedState("all");
            }}
            onValueChange={onSearchChange}
          />
          <Button
            color="default"
            variant="bordered"
            className="font-roboto"
            size="sm"
            radius="sm"
            onClick={navigateToUploadinvoiceDetails}
          >
            Upload Invoices
          </Button>
        </div>
        {showFilter && (
          <div className="absolute top-full mt-2 right-0 w-[20%] px-6 py-4 rounded shadow-xl z-10 bg-white">
            <p className="text-black text-base leading-8 font-roboto font-medium mt-[4%] bg-white">
              Filter
            </p>
            <div className="w-full h-5" />

            <Select
              name="filter"
              item={dropdownFilter}
              label="State"
              placeholder="State"
              onSelect={handleStateSelect}
              className="font-roboto text-xl font-light"
            />
            <div>
              <Row>
                <Button
                  color="default"
                  className="font-roboto font-light text-sm bg-buttonprimary text-white w-[100%] mt-4"
                  size="md"
                  radius="sm"
                  onClick={() => {
                    setSelectedState(tempSelectedState);
                    setShowFilter(false);
                  }}
                >
                  Filter
                </Button>
              </Row>
            </div>
          </div>
        )}
      </div>
    );
  }, [
    filterValue,
    onSearchChange,
    navigateToUploadinvoiceDetails,
    showFilter,
    dropdownFilter,
    tempSelectedState,
  ]);

  const classNames = React.useMemo(
    () => ({
      th: [
        "bg-transparent",
        "text-tableHeaderColor",
        "border-b",
        "border-divider",
        "font-roboto",
        "font-regular",
        "text-sm",
      ],
      td: [
        "group-data-[first=true]:first:before:rounded-none",
        "group-data-[first=true]:last:before:rounded-none",
        "group-data-[middle=true]:before:rounded-none",
        "group-data-[last=true]:first:before:rounded-none",
        "group-data-[last=true]:last:before:rounded-none",
        "font-roboto",
        "font-normal",
        "text-textColorGrey",
      ],
      table: "min-h-[350px]",
      wrapper: "table-wrapper",
    }),
    [],
  );

  const bottomContent = React.useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-between items-center">
        <Pagination
          showControls
          classNames={{
            item: "bg-pageBackground w-6 h-6 min-w-4 font-roboto",
            cursor: "w-6 h-6 min-w-4 font-roboto",
          }}
          color="default"
          isDisabled={hasSearchFilter}
          page={page}
          total={pages}
          variant="light"
          onChange={setPage}
        />
        <div className="flex justify-between items-center">
          <label className="flex items-center text-small font-roboto text-black font-light ">
            Items per page:&nbsp;
            <select
              className="border-none shadow-sm outline-none text-default-400 text-small font-roboto font-light px-1 py-0 rounded-md"
              onChange={onRowsPerPageChange}
              defaultValue={"20"}
            >
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
          </label>
        </div>
      </div>
    );
  }, [hasSearchFilter, page, pages, onRowsPerPageChange]);

  return (
    <div className="flex flex-col">
      <Table
        selectionMode="single"
        classNames={classNames}
        topContent={topContent}
        bottomContent={bottomContent}
        bottomContentPlacement="inside"
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
      >
        <TableHeader columns={COLUMNS}>
          {(column) => (
            <TableColumn
              allowsSorting={column.sortable}
              key={column.key}
              align={column.key === "action" ? "end" : "start"}
              width={column.key === "action" ? 100 : undefined}
              className="text-center"
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          emptyContent={!loading && "No rows to display."}
          items={sortedItems}
          isLoading={loading}
          loadingContent={<Spinner label="Loading..." />}
        >
          {(item) => (
            <TableRow key={`${item.ID}`} className="cursor-pointer h-12">
              {(columnKey) => (
                <TableCell className=" text-center ">
                  {renderCell(item, columnKey)}
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
