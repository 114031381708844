import * as React from "react";
import Cell from "../../../../components/ui/Cell.tsx";
import { AssetInventoryType } from "../../../../types.ts";
import { capitalizeFirstLetter } from "../../../../utils/utils.ts";
import { Tooltip } from "@nextui-org/react";

interface Props {
  index: number;
  asset: AssetInventoryType;
  columnKey: React.Key;
  handleDownloadCsv: (item: AssetInventoryType) => void;
}

export default function RenderColumns({
  index,
  asset,
  columnKey,
  handleDownloadCsv,
}: Props) {
  switch (columnKey) {
    case "sr_no":
      return <Cell>{index + 1}</Cell>;
    case "serial_number":
      return <Cell>{asset.SerialNumber}</Cell>;
    case "brand":
      return <Cell>{asset.Brand}</Cell>;
    case "model":
      return <Cell>{asset.ModelName === "" ? "NA" : asset.ModelName}</Cell>;
    case "state":
      return <Cell>{asset.State}</Cell>;
    case "total_ram":
      return <Cell>{asset.RAM === "" ? "NA" : asset?.RAM}</Cell>;
    case "memory":
      return <Cell>{asset?.Storage === "" ? "NA" : asset?.Storage}</Cell>;
    case "category":
      return <Cell>{asset.AssetCategory}</Cell>;
    case "status":
      return (
        <Cell>
          <p>Delivered</p>
        </Cell>
      );
    case "remark":
      return <Cell>{asset.Remark}</Cell>;
    case "delivery_address":
      return (
        <Cell>
          <Tooltip content={asset.DeliveryAddress} size="sm">
            {capitalizeFirstLetter(asset.DeliveryAddress, 20)}
          </Tooltip>
        </Cell>
      );
    case "description":
      return (
        <Cell>
          {asset.AssetDetailsDescription === ""
            ? "NA"
            : asset.AssetDetailsDescription}
        </Cell>
      );
    case "rent":
      return <Cell>{asset.monthly_rent}</Cell>;
  }
}
