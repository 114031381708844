import { Breadcrumb, BreadcrumbItem } from "flowbite-react";
import { HiHome } from "react-icons/hi";
import PropTypes from "prop-types"; // Optional: for type-checking (if you are using prop-types)

interface BreadcrumbProps {
  locations: { name: string; path: string }[];
}

const BreadcrumbComponent: React.FC<BreadcrumbProps> = ({ locations }) => {
  return (
    <Breadcrumb aria-label="Breadcrumb Example" className="py-3 mt-4 ">
      {/* If you want to show "Home" as the first breadcrumb item */}
      <BreadcrumbItem href="/client" icon={HiHome}>
        Home
      </BreadcrumbItem>
      {locations.map((location, index) => (
        <BreadcrumbItem key={index} href={location.path}>
          {location.name}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default BreadcrumbComponent;
