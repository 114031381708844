import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../../../components/ui/Input.tsx";
import Spacer from "../../../components/ui/Spacer.tsx";
import { LoadingIcon } from "../../../components/ui/Loading.tsx";
import { useNavigate } from "react-router-dom";
import useApi from "../../../hooks/useApi.tsx";
import { EppLoginApi } from "../../../apis.ts";
import useToast from "../../../hooks/useToast.tsx";
import useStore from "../../../hooks/store.tsx";
import secureLocalStorage from "react-secure-storage";

const INTIAL_VALUESLOGIN = {
  email: "",
  password: "",
};

const EppLogin = () => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const { makeApiCall } = useApi();
  const { showToast } = useToast();

  const { setAuthToken } = useStore((state) => ({
    setAuthToken: state.setAuthToken,
    setUser: state.setUser,
  }));

  const validationloginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid  email format")
      .required(" Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleLoginSubmit = React.useCallback(
    async ({ email, password }: typeof INTIAL_VALUESLOGIN) => {
      setLoading(true);
      try {
        const response = await makeApiCall(EppLoginApi(email, password));
        const {
          token,
          code,
          email: new_email,
          message,
          name,
          platform,
          role,
          user_id,
          access_token,
          vendor_status,
        }: {
          token: string;
          code: string;
          email: string;
          message: string;
          name: string;
          platform: string;
          role: string;
          user_id: number;
          access_token: string;
          vendor_status: number;
        } = response;
        if (response?.message === "success") {
          showToast("Login Successful!!", { type: "success" });

          localStorage.setItem("authToken", token);
          secureLocalStorage.setItem("token", token);
          secureLocalStorage.setItem("code", code);
          secureLocalStorage.setItem("email", new_email);
          secureLocalStorage.setItem("message", message);
          secureLocalStorage.setItem("name", name);
          secureLocalStorage.setItem("platform", platform);
          secureLocalStorage.setItem("role", role);
          secureLocalStorage.setItem("user_id", user_id);
          secureLocalStorage.setItem("epp_access_token", access_token);

          const savedToken = secureLocalStorage.getItem("token");

          if (savedToken) {
            setAuthToken(token);

            if (role === "employee") {
              navigate("/dashboard/products");
            } else if (role === "vendor") {
              if (vendor_status === 0) {
                navigate("/client/vendor-profile");
              } else {
                navigate("/client");
              }
            } else {
              navigate("/dashboard");
            }
          } else {
            showToast("Failed to save token. Please try again.", {
              type: "error",
            });
          }
        } else {
          showToast("Something went wrong!!", { type: "error" });
        }
      } catch (error) {
        showToast("Something went wrong!!", { type: "error" });
        navigate("/epp/login");
      } finally {
        setLoading(false);
      }
    },
    [makeApiCall, navigate, setAuthToken, showToast], // Ensure dependencies are listed
  );

  return (
    <section className="bg-white ">
      <div className="flex flex-row justify-between">
        <div className="w-full  hidden md:block">
          <div className=" h-screen flex items-center justify-center">
            <div
              className="w-full h-full bg-center bg-no-repeat bg-cover"
              style={{
                backgroundImage: `url("https://storage.googleapis.com/website_blog_images/clientportalloginimage.svg")`,
              }}
            >
              <div className=" mt-[8%]">
                <div className=" flex w-full flex-col">
                  <img
                    src={
                      "https://connectelpl.com/_next/static/media/logocrpl.5ecbd723.svg"
                    }
                    alt="banner"
                    height={50}
                    className="cursor-pointer transfor self-center "
                  />
                  <Spacer size="xs" />
                </div>
                <div>
                  <div className=" mx-auto max-w-2xl ">
                    <Formik
                      initialValues={INTIAL_VALUESLOGIN}
                      onSubmit={handleLoginSubmit}
                      validateOnBlur
                      validateOnChange
                      validationSchema={validationloginSchema}
                      enableReinitialize
                    >
                      <Form>
                        <div
                          style={{ borderColor: "#DADADA", width: "80%" }}
                          className="px-10 mx-auto max-w-xl    rounded-2xl"
                        >
                          <div className="flex w-full justify-center items-center">
                            <p
                              style={{
                                fontSize: "18px",
                                fontWeight: 400,
                                lineHeight: "29px",
                                paddingTop: "10px",
                              }}
                              className="my-2 text-xl font-normal font-roboto text-gray-900"
                            >
                              Log In
                            </p>
                          </div>
                          <Input
                            label="Registered Email"
                            placeholder="Email"
                            name="email"
                          />
                          <Spacer size="xs" />

                          <Input
                            label="Password"
                            placeholder="Password"
                            name="password"
                            type="password"
                          />
                          <Spacer size="sm" />
                        </div>
                        <Spacer size="sm" />

                        <div className="flex justify-center items-center">
                          {loading ? (
                            <button
                              disabled
                              style={{ backgroundColor: "#00006D" }}
                              type="button"
                              className=" focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg"
                            >
                              <div className="flex flex-row justify-center items-center">
                                <div className="w-10 h-1" />

                                <LoadingIcon />
                                <p
                                  style={{
                                    paddingTop: 8,
                                    paddingBottom: 8,
                                    fontSize: "15px",
                                    fontWeight: "400",
                                    paddingRight: 64,
                                    paddingLeft: 10,
                                    color: "#fff",
                                    textAlign: "center",
                                  }}
                                >
                                  Loading ...
                                </p>
                              </div>
                            </button>
                          ) : (
                            <button
                              type="submit"
                              style={{ backgroundColor: "#00006D" }}
                              className=" focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg "
                            >
                              <p
                                style={{
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  fontSize: "15px",
                                  fontWeight: "400",
                                  paddingLeft: 64,
                                  paddingRight: 64,
                                  color: "#fff",
                                  textAlign: "center",
                                }}
                              >
                                Login
                              </p>
                            </button>
                          )}
                        </div>
                        <Spacer size="sm" />
                        <div className="flex justify-center items-center">
                          <p className=" text-black font-roboto font-normal text-xs">
                            For a demo account, please contact
                            connect@connectrpl.com
                          </p>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute z-10 bottom-0">
              <div className="flex flex-row justify-end  w-screen px-5">
                <p className="font-light text-sm font-roboto text-textColorSecondary">
                  Copyright © 2023 - 2024 Connect Residuary Private Limited.
                  All rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EppLogin;
