import * as React from "react";
import Spacer from "../../../components/ui/Spacer.tsx";
import PoOrderDetail from "../../../components/pages/epp/vendor/view-orders/PoOrderDetail.tsx";
import Main from "../../../components/ui/sidebar/Main.tsx";

export default function PoDetail() {
  return (
    <Main>
      <div className="text-black bg-pageBackground px-10 min-h-screen">
        <Spacer size="sm" />
        <PoOrderDetail />
      </div>
    </Main>
  );
}
