import * as React from "react";
import {
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  TableCell,
  Spinner,
  Pagination,
  SortDescriptor,
  Chip,
} from "@nextui-org/react";
import { OrderType } from "../../../../types";
import { GetOrdersApi } from "../../../../apis.ts";
import useApi from "../../../../hooks/useApi.tsx";
import { formatDateIntl } from "../../../../utils/utils.ts";
import Action from "./order-details/Action.tsx";

const COLUMNS = [
  {
    name: "Sr No.",
    key: "sr_no",
  },
  {
    name: "Order Id",
    key: "order_id",
  },
  {
    name: "Order Value",
    key: "order_value",
  },

  {
    name: "Creation Date",
    key: "creation_date",
  },
  {
    name: "Quantity",
    key: "quantity",
  },
  {
    name: "Status",
    key: "status",
  },
  {
    name: "Action",
    key: "action",
  },
];

export default function MyOrders() {
  const [orders, setOrders] = React.useState<OrderType[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [filterValue, setFilterValue] = React.useState("");
  const [sortDescriptor, setSortDescriptor] = React.useState<SortDescriptor>({
    column: "employee_name",
    direction: "ascending",
  });

  const hasSearchFilter = Boolean(filterValue);

  const filteredItems = React.useMemo(() => {
    let filteredUsers = [...orders];

    if (hasSearchFilter) {
      filteredUsers = filteredUsers.filter((orders) =>
        orders.order_id.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }

    return filteredUsers;
  }, [orders, filterValue, hasSearchFilter]);

  const { makeApiCall } = useApi();

  React.useEffect(() => {
    setLoading(true);
    makeApiCall(GetOrdersApi())
      .then((response) => {
        if (response !== undefined) {
          setOrders(response?.orders);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [makeApiCall]);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return filteredItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage]);

  const sortedItems = React.useMemo(() => {
    return [...items].sort((a: OrderType, b: OrderType) => {
      const first = a[sortDescriptor.column as keyof OrderType] as number;
      const second = b[sortDescriptor.column as keyof OrderType] as number;
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);

  const onRowsPerPageChange = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setRowsPerPage(Number(e.target.value));
      setPage(1);
    },
    [],
  );

  const pages = React.useMemo(() => {
    if (orders?.length === 0) {
      return 1;
    }
    return Math.ceil((orders?.length ?? 1) / rowsPerPage);
  }, [orders?.length, rowsPerPage]);

  const renderStatus = React.useCallback((item: OrderType) => {
    switch (item?.status) {
      case "approved":
        return (
          <Chip variant="flat" color="success" size="sm">
            <span className="font-extrabold">&#8226;</span> Approved
          </Chip>
        );
      default:
        return (
          <Chip variant="flat" color="default" size="sm">
            <span className="font-extrabold">&#8226;</span> Pending
          </Chip>
        );
    }
  }, []);

  const bottomContent = React.useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-between items-center">
        <Pagination
          showControls
          classNames={{
            item: "bg-pageBackground w-6 h-6 min-w-4 font-roboto",
            cursor: "w-6 h-6 min-w-4 font-roboto",
          }}
          color="default"
          isDisabled={hasSearchFilter}
          page={page}
          total={pages}
          variant="light"
          onChange={setPage}
        />
        <div className="flex justify-between items-center">
          <label className="flex items-center text-small font-roboto text-black font-light ">
            Items per page :&nbsp;
            <select
              className="border-none shadow-sm outline-none text-default-400 text-small font-roboto font-light px-1 py-0 rounded-md"
              onChange={onRowsPerPageChange}
              defaultValue={"20"}
            >
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
          </label>
        </div>
      </div>
    );
  }, [hasSearchFilter, page, pages, onRowsPerPageChange]);

  const renderCell = React.useCallback(
    (item: OrderType, columnKey: React.Key) => {
      const index = sortedItems
        ?.map((object) => object.order_id)
        .indexOf(item.order_id);
      switch (columnKey) {
        case "sr_no":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">{index + 1}</p>
            </div>
          );
        case "order_id":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">{item.order_id}</p>
            </div>
          );
        case "order_value":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">{item.order_value}</p>
            </div>
          );
        case "creation_date":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {formatDateIntl(item.order_date)}
              </p>
            </div>
          );
        case "quantity":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">{item.quantity}</p>
            </div>
          );
        case "status":
          return renderStatus(item);
        case "action":
          return <Action item={item} />;
      }
    },
    [renderStatus, sortedItems],
  );

  const classNames = React.useMemo(
    () => ({
      th: [
        "bg-transparent",
        "text-tableHeaderColor",
        "border-b",
        "border-divider",
        "font-roboto",
        "font-regular",
        "text-sm",
      ],
      td: [
        "group-data-[first=true]:first:before:rounded-none",
        "group-data-[first=true]:last:before:rounded-none",
        "group-data-[middle=true]:before:rounded-none",
        "group-data-[last=true]:first:before:rounded-none",
        "group-data-[last=true]:last:before:rounded-none",
        "font-roboto",
        "font-normal",
        "text-textColorGrey",
      ],
      table: "min-h-[350px]",
      wrapper: "table-wrapper",
    }),
    [],
  );

  return (
    <Table
      selectionMode="single"
      classNames={classNames}
      bottomContent={bottomContent}
      bottomContentPlacement="inside"
      sortDescriptor={sortDescriptor}
      onSortChange={setSortDescriptor}
    >
      <TableHeader columns={COLUMNS}>
        {(column) => (
          <TableColumn
            key={column.key}
            align={column.key === "action" ? "end" : "start"}
            width={column.key === "action" ? 100 : undefined}
            className="text-center"
          >
            {column.name}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody
        emptyContent={!loading && "No rows to display."}
        items={sortedItems}
        isLoading={loading}
        loadingContent={<Spinner label="Loading..." />}
      >
        {(item) => (
          <TableRow key={`${item.id}`} className="cursor-pointer h-12 ">
            {(columnKey) => (
              <TableCell className="text-center">
                {renderCell(item, columnKey)}
              </TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
