import * as React from "react";
import Spacer from "../../../../../components/ui/Spacer.tsx";
import Main from "../../../../../components/ui/sidebar/Main.tsx";
import OrderDetailList from "../../../../../components/pages/epp/vendor/view-orders/OrderDetailList.tsx";

export default function OrderDetailslView() {
  return (
    <Main>
      <div className="text-black bg-pageBackground px-10 min-h-screen">
        <Spacer size="md" />
        <div className="flex flex-col">
          <p className="text-black text-lg leading-8 font-roboto font-normal mt-[2%]">
            Orders Details
          </p>
        </div>
        <OrderDetailList />
      </div>
    </Main>
  );
}
