import React from "react";
import Spacer from "../../../components/ui/Spacer.tsx";
import useApi from "../../../hooks/useApi.tsx";
import { GetEppEmployesActiveRentalDevicesApi } from "../../../apis.ts";
import { AssetInventoryType } from "../../../types.ts";
import Main from "../../../components/ui/sidebar/Main.tsx";
import EmployeeActiveRentalsList from "./components/List.tsx";

const EmployeeActiveRentals = () => {
  const { makeApiCall } = useApi();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const scheduleNumberParam = params.get("schedule_number");
  const statusParam = params.get("status");

  const [deviceInfo, setDeviceInfo] = React.useState<AssetInventoryType[]>([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    makeApiCall(GetEppEmployesActiveRentalDevicesApi())
      .then((response) => {
        if (response !== undefined) {
          setDeviceInfo(response);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [makeApiCall, scheduleNumberParam, statusParam]);

  return (
    <Main>
      <div className="text-black bg-pageBackground px-10 min-h-screen">
        <Spacer size="sm" />
        <EmployeeActiveRentalsList deviceInfo={deviceInfo} loading={loading} />
      </div>
    </Main>
  );
};

export default EmployeeActiveRentals;
